import Select from "react-select";
import {AdultSelectOptions, ChildSelectOptions} from "../enums/SelectOptions";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {DateTime} from "luxon";
export default function AddPage() {
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);

    const [validatedPassengers, setValidatedPassengers] = useState(1);
    const [isDealsLoading, setIsDealsLoading] = useState(false);
    const [baseDeal, setBaseDeal] = useState<any>();

    const [results, setResults] = useState<{
        price: number,
        flyFromDep: string,
        flyToDep: string,
        flyFromRet: string,
        flyToRet: string,
        departure_date: string,
        return_date: string,
        link: string,
        airlines: string[],
        cityTo: string,
        countryTo: string
        cityFrom: string,
        countryFrom: string
    }[]>([]);
    const navigate = useNavigate();
    const {addToken} = useParams();


    const validatePassengers = () => {
        setIsDealsLoading(true)
        setValidatedPassengers(adults + children + infants);
        setResults([]);
        axios.post("/from_add/" + addToken, {
            adults: adults,
            children: children,
            infants: infants
        }).then((res) => {
            setResults(res.data)
        }).finally(() => {
            setIsDealsLoading(false)
        })
    }



    useEffect(() => {
        validatePassengers();
        axios.get("/get_add/" + addToken).then((res) => {
            setBaseDeal(res.data)
        });
    }, []);
    return (
        <div>
            <h1 className={"text-4xl text-emerald-500 text-center mt-8 cursor-pointer hover:border-2 hover:border-emerald-500 w-fit m-auto rounded-full p-4 transition"}
            onClick={() => {
                navigate("/")
            }}>Weekendeal</h1>
            <div
                className={" drop-shadow-lg bg-gray-200 min-h-[200px] w-full md:w-3/5 m-auto mt-8 text-xl rounded-lg text-center p-8"}>
                {/*<h2 className={"text-4xl"}>Paris - Strasborug</h2>*/}
                <div className={"flex w-10/12 m-auto justify-around flex-wrap wrap"}>
                    <div className={""}>
                        <h2 className={"text-2xl "}><strong>{baseDeal?.cityFrom}</strong>, {baseDeal?.countryFrom?.name} to <strong>{baseDeal?.cityTo}</strong>, {baseDeal?.countryTo?.name}</h2>
                    </div>

                </div>

                <div className={"mt-8"}>

                    <div className={"flex flex-wrap wrap w-2/5 m-auto mt-2 text-left justify-between"}>
                        <div className={"w-1/4 min-w-[150px]"}>
                            <label>Adults :</label>
                            <Select className={"text-center"} options={AdultSelectOptions}
                                    defaultValue={AdultSelectOptions[0]} onChange={(e) => {
                                setAdults(e!.value)
                            }}/>
                        </div>
                        <div className={"w-1/4 min-w-[150px]"}>
                            <label>Children :</label>
                            <Select className={"text-center"} options={ChildSelectOptions}
                                    defaultValue={ChildSelectOptions[0]} onChange={(e) => {
                                setChildren(e!.value)
                            }}/>
                        </div>
                        <div className={"w-1/4 min-w-[150px]"}>
                            <label>Infants :</label>
                            <Select className={"text-center"} options={ChildSelectOptions}
                                    defaultValue={ChildSelectOptions[0]} onChange={(e) => {
                                setInfants(e!.value)
                            }}/>
                        </div>

                    </div>
                </div>
                <div className={"mt-8 text-center"}>
                    <button
                        className={"bg-emerald-400 text-white px-4 py-2 rounded hover:bg-emerald-600 transition md:mr-2"}
                        onClick={() => {
                            validatePassengers()
                        }}>
                        Validate changes
                    </button>
                    <div>or</div>
                    <button
                        className={"bg-emerald-400 text-white px-4 py-2 rounded hover:bg-emerald-600 transition md:ml-2"}
                        onClick={() => {
                            navigate("/")
                        }}>
                        More options...
                    </button>
                </div>
                {/*    Results are cards (3 per line when md:). cards are white and have a shadow. they have a ligh emerald border rounded*/}
                <div className={"mt-8"}>
                    <h2 className={"text-xl mb-4"}>Results for <strong>{baseDeal?.cityFrom}</strong> ({baseDeal?.countryFrom?.name}) to <strong>{baseDeal?.cityTo}</strong> ({baseDeal?.countryTo?.name})</h2>
                    {isDealsLoading && (
                        <div role="status">

                            <svg aria-hidden="true"
                                 className="inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            We are looking for the best deals...
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                    <div className={"flex flex-wrap wrap justify-between  m-auto "}>
                        {results.map((result, index) => {
                            return (
                                <div className={"w-1/4 mt-4 min-w-[300px]"} key={index}>

                                    <div
                                        className={" bg-white shadow-lg rounded-lg p-4 hover:shadow-2xl cursor-pointer transition border-emerald-500 " + (index === 0 ? 'border-4 ' : 'border-2')}   onClick={() => {
                                                        window.open(result.link, '_blank')
                                                    }}>
                                        <div className={""}>
                                            <div>
                                                <h3>{DateTime.fromISO(result.departure_date).toFormat("ccc'.' dd/LL/yy 'at' HH:mm")}</h3>
                                                <h3>to</h3>
                                                <h3>{DateTime.fromISO(result.return_date).toFormat("ccc'.' dd/LL/yy 'at' HH:mm")}</h3>
                                            </div>
                                            <div className={"flex flex-col mt-4"}>
                                                <h2 className={"text-lg"}>{result.cityFrom} ({result.flyFromDep} - {result.flyToRet}), {result.countryFrom}</h2>
                                                <h2 className={"text-lg"}>to</h2>
                                                <h2 className={"text-lg"}>{result.cityTo} ({result.flyToDep} - {result.flyFromRet}), {result.countryTo}</h2>
                                            </div>
                                            <div className={"flex flex-col mt-8"}>
                                                <h2 className={"text-4xl font-bold"}>€ {result.price} </h2>
                                                <span
                                                    className={"text-lg"}>({validatedPassengers}) passenger(s)</span>
                                            </div>
                                            <div className={"mt-4"}>
                                                <button
                                                    className={"bg-emerald-500 text-white px-4 py-2 rounded hover:bg-emerald-700 transition"}
                                                    onClick={() => {
                                                        window.open(result.link, '_blank')
                                                    }}>Book
                                                    on Kiwi.com
                                                </button>
                                            </div>
                                            {index === 0 && (
                                                <div className={"mt-4 text-2xl text-emerald-500"}>
                                                    best price
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                </div>
                            )
                        })
                        }
                        {/*    <div*/}
                        {/*        className={"bg-white drop-shadow-lg rounded-lg p-4 hover:drop-shadow-2xl cursor-pointer transition border-emerald-500 border-8"}>*/}
                        {/*        <div className={""}>*/}
                        {/*            <div>*/}
                        {/*                <h3>Fri. 10/02 12h35</h3>*/}
                        {/*                <h3>to</h3>*/}
                        {/*                <h3>Sun. 12/02 12h35</h3>*/}
                        {/*            </div>*/}
                        {/*            <div className={"flex flex-col mt-4"}>*/}
                        {/*                <h2 className={"text-lg"}>Paris CDG, France</h2>*/}
                        {/*                <h2 className={"text-lg"}>to</h2>*/}
                        {/*                <h2 className={"text-lg "}>Strasbourg ETZ, France</h2>*/}
                        {/*            </div>*/}
                        {/*            <div className={"flex flex-col mt-8"}>*/}
                        {/*                <h2 className={"text-4xl font-bold"}>€ 20 </h2>*/}
                        {/*                <span className={"text-lg"}>(1) passenger(s)</span>*/}
                        {/*            </div>*/}
                        {/*            <div className={"mt-4"}>*/}
                        {/*                <button*/}
                        {/*                    className={"bg-emerald-500 text-white px-4 py-2 rounded hover:bg-emerald-700 transition"}>Book*/}
                        {/*                    on Kiwi.com*/}
                        {/*                </button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"w-1/4 mt-4"}>*/}
                        {/*    <div*/}
                        {/*        className={"bg-white drop-shadow-lg rounded-lg p-4 hover:drop-shadow-2xl cursor-pointer transition border-emerald-500 border-2"}>*/}
                        {/*        <div className={""}>*/}
                        {/*            <div>*/}
                        {/*                <h3>Fri. 10/02 12h35</h3>*/}
                        {/*                <h3>to</h3>*/}
                        {/*                <h3>Sun. 12/02 12h35</h3>*/}
                        {/*            </div>*/}
                        {/*            <div className={"flex flex-col mt-4"}>*/}
                        {/*                <h2 className={"text-lg"}>Paris CDG, France</h2>*/}
                        {/*                <h2 className={"text-lg"}>to</h2>*/}
                        {/*                <h2 className={"text-lg "}>Strasbourg ETZ, France</h2>*/}
                        {/*            </div>*/}
                        {/*            <div className={"flex flex-col mt-8"}>*/}
                        {/*                <h2 className={"text-4xl font-bold"}>€ 20 </h2>*/}
                        {/*                <span className={"text-lg"}>(1) passenger(s)</span>*/}
                        {/*            </div>*/}
                        {/*            <div className={"mt-4"}>*/}
                        {/*                <button*/}
                        {/*                    className={"bg-emerald-500 text-white px-4 py-2 rounded hover:bg-emerald-700 transition"}>Book*/}
                        {/*                    on Kiwi.com*/}
                        {/*                </button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"w-1/4 mt-4"}>*/}
                        {/*    <div*/}
                        {/*        className={"bg-white drop-shadow-lg rounded-lg p-4 hover:drop-shadow-2xl cursor-pointer transition border-emerald-500 border-2"}>*/}
                        {/*        <div className={""}>*/}
                        {/*            <div>*/}
                        {/*                <h3>Fri. 10/02 12h35</h3>*/}
                        {/*                <h3>to</h3>*/}
                        {/*                <h3>Sun. 12/02 12h35</h3>*/}
                        {/*            </div>*/}
                        {/*            <div className={"flex flex-col mt-4"}>*/}
                        {/*                <h2 className={"text-lg"}>Paris CDG, France</h2>*/}
                        {/*                <h2 className={"text-lg"}>to</h2>*/}
                        {/*                <h2 className={"text-lg "}>Strasbourg ETZ, France</h2>*/}
                        {/*            </div>*/}
                        {/*            <div className={"flex flex-col mt-8"}>*/}
                        {/*                <h2 className={"text-4xl font-bold"}>€ 20 </h2>*/}
                        {/*                <span className={"text-lg"}>(1) passenger(s)</span>*/}
                        {/*            </div>*/}
                        {/*            <div className={"mt-4"}>*/}
                        {/*                <button*/}
                        {/*                    className={"bg-emerald-500 text-white px-4 py-2 rounded hover:bg-emerald-700 transition"}>Book*/}
                        {/*                    on Kiwi.com*/}
                        {/*                </button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                    </div>

                    <div className={"flex mt-8 ml-auto w-1/5"}>
                        <span className={"text-lg mr-4"}>Powered by</span>
                        <a href={"https://kiwi.com"} target={"_blank"}>
                            <img src={"https://images.kiwi.com/common/kiwicom-logo.svg"} alt={"powered by Kiwi.com"}/>
                        </a>
                    </div>
                </div>


            </div>
            <div className={"text-center mt-4 underline cursor-pointer"} onClick={() => {
                navigate("/terms_and_conditions")
            }}>
                Terms and conditions
            </div>
        </div>
    )
}