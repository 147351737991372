import React from 'react';
import axios from "axios";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {DestionationRoutesComponent} from "./DestionationRoutesComponent";
import {FLY_DAYS} from "../enums/FLY_DAYS";
export default function HomePage() {
    const [from, setFrom] = React.useState("");
    const [to, setTo] = React.useState("");
    const [adults, setAdults] = React.useState(1);
    const [children, setChildren] = React.useState(0);
    const [infants, setInfants] = React.useState(0);
    const [departureTime, setDepartureTime] = React.useState("12:00");
    const [returnDepartureTime, setReturnDepartureTime] = React.useState("12:00");
    const [email, setEmail] = React.useState("");
    const [departureDays, setDepartureDays] = React.useState([FLY_DAYS.FRIDAY, FLY_DAYS.SATURDAY] as FLY_DAYS[]);
    const [returnDays, setReturnDays] = React.useState([FLY_DAYS.SUNDAY, FLY_DAYS.MONDAY] as FLY_DAYS[]);
    const [dealsPerDestination, setDealsPerDestination] = React.useState([] as any[]);
    const [excludedAirlines, setExcludedAirlines] = React.useState([] as any[]);

    const [isDealLoading, setIsDealLoading] = React.useState(false);

    const treatExcludeAirlines = (airlines: string[]) => {
        let newAirlines = airlines
        if(newAirlines.includes("FR")) {
            newAirlines.push("RK")
        }

        return newAirlines
    }

    const createSubscription = () => {
        // Model is {
        // 	"user_email": "a18.spitz@gmail.com",
        // 	"departure_cities": ["paris_fr"],
        // 	"scope": "europe",
        // 	"max_travel_time": 300,
        // 	"excluded": []
        // }
        axios.post("/setup_profile", {
            "user_email": email,
            "departure_cities": [from],
            "scope": to,
            "max_travel_time": 10000,
            "departure_days": departureDays,
            "departure_time": departureTime,
            "return_days": returnDays,
            "return_time": returnDepartureTime,
            "adults": adults,
            "children": children,
            "infants": infants,
            "excluded": treatExcludeAirlines(excludedAirlines)
        }).then((response) => {
            console.log(response.data)
        });
    }


    const searchFrom = (input: string, callback: any) => {
        axios.get(`/search_location/${input}/city`)
            .then((response) => {
                callback(response.data.map((item: any) => {
                    return {value: item.city_id as string, label: item.city as string}
                }));
            })
            .catch((error) => {
                callback([]);
                console.log(error);
            });

    }

    const searchTo = (input: string, callback: any) => {
        axios.get(`/search_location/${input}/any`)
            .then((response) => {
                callback(response.data.map((item: any) => {
                    return {value: item.city_id, label: item.city}
                }));
            })
            .catch((error) => {
                callback([]);
                console.log(error);
            });

    }

    const searchAirline = (input: string, callback: any) => {
        if (input.length < 2) return callback([]);
        axios.get(`/search_airline/${input}`)
            .then((response) => {
                callback(response.data.map((item: any) => {
                    return {value: item.code as string, label: item.name as string}
                }));
            })
            .catch((error) => {
                callback([]);
                console.log(error);
            });

    }

    const getDeals = () => {
        setIsDealLoading(true);
        setDealsPerDestination([])
        axios.post("/live_search_anonymous", {
            "departure_cities": [from],
            "scope": to,
            "max_travel_time": 10000,
            "departure_days": departureDays.sort(),
            "departure_time": departureTime,
            "return_days": returnDays.sort(),
            "return_time": returnDepartureTime,
            "adults": adults,
            "children": children,
            "infants": infants,
            "excluded": treatExcludeAirlines(excludedAirlines)
        }).then((response) => {
            setDealsPerDestination(response.data)
        }).finally(() => {
            setIsDealLoading(false)
        });
    }


    return (
        <div className={"text-center pt-8 md:px-8"}>
            <h1 className={"text-4xl font-bold text-emerald-500"}>Weekendeal</h1>
            {/*    nice input */}
            <div className={"flex flex-col items-center justify-center mt-4 md:mt-16"}>
                <div className={"flex flex-row items-center justify-center flex-wrap"}>
                    <div className={"m-4 md:w-4/12 md:m-0"}>
                        <h2 className={"text-2xl font-bold text-emerald-500 mb-16   "}>A cheap trip in the next 60 days
                            !</h2>
                        Subscribe to our email list to receive the best travel deals of the next 60 days !
                    </div>
                    <div className={"text-left m-4 md:m-0 md:ml-8 md:w-7/12"}>
                        <label>From city : </label>
                        <AsyncSelect cacheOptions loadOptions={searchFrom} defaultOptions onChange={(e: any) => {
                            setFrom(e.value)
                        }}/>
                        <label>To (region / city / continent) : </label>
                        <AsyncSelect cacheOptions loadOptions={searchTo} defaultOptions onChange={(e: any) => {
                            setTo(e.value)
                        }}/>
                        <label>Airlines to avoid : </label>
                        <AsyncSelect isMulti cacheOptions loadOptions={searchAirline} defaultOptions onChange={(e: any) => {
                            setExcludedAirlines(e.map((item: any) => item.value))
                        }}/>
                        <div className={"flex justify-between"}>
                            <div className={"md:w-3/12 "}>
                                <label>Adults (over 11) : </label>
                                <Select options={[
                                    {value: 1, label: "1"},
                                    {value: 2, label: "2"},
                                    {value: 3, label: "3"},
                                    {value: 4, label: "4"},
                                    {value: 5, label: "5"},
                                ]} onChange={(e: any) => {
                                    setAdults(e.value)
                                }} defaultValue={{value: 1, label: "1"}}/>
                            </div>
                            <div className={"md:w-3/12"}>
                                <label>Children (2 - 11): </label>
                                <Select options={[
                                    {value: 0, label: "0"},
                                    {value: 1, label: "1"},
                                    {value: 2, label: "2"},
                                    {value: 3, label: "3"},
                                    {value: 4, label: "4"},
                                ]} onChange={(e: any) => {
                                    setChildren(e.value)
                                }}
                                        defaultValue={{value: 0, label: "0"}}/>
                            </div>
                            <div className={"md:w-3/12"}>
                                <label>Infants (under 2): </label>
                                <Select options={[
                                    {value: 0, label: "0"},
                                    {value: 1, label: "1"},
                                    {value: 2, label: "2"},
                                    {value: 3, label: "3"},
                                    {value: 4, label: "4"},
                                ]} onChange={(e: any) => {
                                    setInfants(e.value)
                                }}
                                        defaultValue={{value: 0, label: "0"}}/>
                            </div>
                        </div>


                        {/*Multi select to select days of departure*/}
                        <label>Departure days : </label>
                        <Select isMulti options={[
                            {value: FLY_DAYS.FRIDAY, label: "Friday"},
                            {value: FLY_DAYS.SATURDAY, label: "Saturday"},
                        ]} onChange={(e: any) => {
                            setDepartureDays(e.map((item: any) => item.value))
                        }} defaultValue={[{value: FLY_DAYS.FRIDAY, label: "Friday"},
                            {value: FLY_DAYS.SATURDAY, label: "Saturday"}]}/>
                        {/*Time of the day for departure*/}
                        <label>Departure time from : </label>
                        <Select options={[
                            {value: "00:00", label: "Morning (from midnight)"},
                            {value: "12:00", label: "Afternoon (from noon)"},
                            {value: "18:00", label: "Evening (from 6pm)"},
                            {value: "22:00", label: "Night (from 10pm)"},
                        ]} onChange={(e: any) => {
                            setDepartureTime(e.value)
                        }} defaultValue={{value: "12:00", label: "Afternoon (from noon)"}}/>
                        {/*Multi select to select days of coming back*/}
                        <label>Return days : </label>
                        <Select isMulti options={[
                            {value: FLY_DAYS.SUNDAY, label: "Sunday"},
                            {value: FLY_DAYS.MONDAY, label: "Monday"},
                        ]} onChange={(e: any) => {
                            setReturnDays(e.map((item: any) => item.value))

                        }} defaultValue={[{value: FLY_DAYS.SUNDAY, label: "Sunday"},
                            {value: FLY_DAYS.MONDAY, label: "Monday"}]}/>
                        {/*Time of the day for coming back*/}
                        <label>Return time from : </label>
                        <Select options={[
                            {value: "00:00", label: "Morning (from midnight)"},
                            {value: "12:00", label: "Afternoon (from noon)"},
                            {value: "18:00", label: "Evening (from 6pm)"},
                            {value: "22:00", label: "Night (from 10pm)"},
                        ]} onChange={(e: any) => {
                            setReturnDepartureTime(e.value)
                        }} defaultValue={{value: "12:00", label: "Afternoon (from noon)"}}/>

                        {/*Email*/}
                        <label>Email (mandatory for subscriptions) :</label>
                        <input className={"border-2 border-emerald-500 rounded-md p-2  w-full"} type="text"
                               placeholder="Email" onChange={(e) => {
                            setEmail(e.target.value)
                        }}/>
                        <div className={"flex flex-row items-center justify-center mt-4"}>
                            <button
                                className={"bg-cyan-500 text-white rounded-md p-2 mr-2 w-full hover:bg-cyan-700 transition"}
                                onClick={() => {
                                    getDeals()
                                }}>Search
                            </button>
                            <button
                                className={"bg-emerald-500 text-white rounded-md p-2 ml-2 w-full hover:bg-emerald-700 transition"}
                                onClick={() => {
                                    createSubscription()
                                }}>Subscribe
                            </button>
                        </div>


                    </div>
                </div>
            </div>

            <div className={"flex flex-col items-center justify-center mt-8"}>
                <div className={"w-4/5 mb-16"}>
                    {!isDealLoading && dealsPerDestination.length > 0 && (
                        <h2 className={"text-2xl font-bold text-emerald-500 mb-8   "}>Deals</h2>)}

                    {isDealLoading && (
                        <div role="status">

                            <svg aria-hidden="true"
                                 className="inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            We are looking for the best deals...
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}

                    {dealsPerDestination.map((item) => {
                            return (
                                // Div with hover effect (shadow, transition,and clickable, etc.)
                                <DestionationRoutesComponent destinationItem={item} key={item.cityTo}/>
                            )

                        }
                    )}
                </div>
            </div>

        </div>
    );
}