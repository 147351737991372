import {useNavigate} from "react-router-dom";

export default function TermsAndConditions() {
    const navigate = useNavigate();
    return (
        <div className={"text-xl w-3/5 m-auto mt-8 text-center"}>
    {/*       Terms and Conditions for Kiwi.com Ticket Redirection Website:*/}

    {/*Introduction: These terms and conditions apply to the use of the website located at [website URL], (hereinafter referred to as "Website"). By accessing and using the Website, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not use the Website.*/}

    {/*Services: The Website provides a platform for redirecting to the website of Kiwi.com for the purpose of purchasing plane tickets. The Website acts solely as a link between you and Kiwi.com. The Website does not own or control the products or services offered by Kiwi.com.*/}

    {/*Product Availability: The availability of products and services offered by Kiwi.com is subject to change without notice. The Website does not guarantee the availability of any product or service.*/}

    {/*Prices: The prices of products and services offered by Kiwi.com may change without notice. The Website does not guarantee the accuracy of prices displayed on Kiwi.com.*/}

    {/*Payment: Payment for products and services offered by Kiwi.com*/}
    {/*        Write this formated*/}
            <p className={"text-2xl cursor-pointer underline mb-8 underline-offset-4"} onClick={() => {
                navigate(-1)
            }}>Back to previous page...</p>
            <h2 className={"text-4xl"}>Terms and Conditions for Kiwi.com Ticket Redirection Website:</h2>
            <h3 className={"text-2xl font-bold text-center mt-8"}>Introduction:</h3>
            <p>These terms and conditions apply to the use of the website located at [website URL], (hereinafter referred to as "Website"). By accessing and using the Website, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not use the Website.</p>
            <h3 className={"text-2xl font-bold text-center mt-8"}>Services:</h3>
            <p>The Website provides a platform for redirecting to the website of Kiwi.com for the purpose of purchasing plane tickets. The Website acts solely as a link between you and Kiwi.com. The Website does not own or control the products or services offered by Kiwi.com.</p>
            <h3 className={"text-2xl font-bold text-center mt-8"}>Product Availability:</h3>
            <p>The availability of products and services offered by Kiwi.com is subject to change without notice. The Website does not guarantee the availability of any product or service.</p>
            <h3 className={"text-2xl font-bold text-center mt-8"}>Prices:</h3>
            <p>The prices of products and services offered by Kiwi.com may change without notice. The Website does not guarantee the accuracy of prices displayed on Kiwi.com.</p>
            <h3 className={"text-2xl font-bold text-center mt-8"}>Payment:</h3>
            <p>Payment for products and services areoffered by Kiwi.com</p>
        </div>
    )
}