import React from "react";
import {DateTime} from "luxon";

interface DestionationRoutesComponentProps {
    destinationItem: any;
}

export function DestionationRoutesComponent({destinationItem}: DestionationRoutesComponentProps) {
    const [routesOpened, setRoutesOpened] = React.useState(false);
    return (
        <div
            className={"drop-shadow-md hover:drop-shadow-xl mt-8 bg-gray-100 rounded px-8 py-4 cursor-pointer"}
            onClick={() => {
                setRoutesOpened(!routesOpened)
            }}>


            <div className={"flex flex-wrap wrap "}>
                <div className={"md:w-1/5 text-wrap text-left "}>
                    <h3 className={"text-2xl text-emerald-500"}>
                        <strong>{destinationItem.cityTo}</strong> ({destinationItem.countryTo})</h3>

                </div>
                <div className={"w-full md:w-1/5 text-wrap text-left md:text-center md:ml-8"}>
                    <h3 className={"text-2xl text-emerald-500"}> from <span
                        className={"font-bold"}>{destinationItem.lowest.price} €</span> (roundtrip)</h3>

                </div>

            </div>
            <div onClick={(e) => {
                e.stopPropagation()
            }}>
                {routesOpened && (
                    <div>
                        {destinationItem.flights.sort((a: any, b: any) => a.price - b.price).slice(0, 10).map((flight: any) => {
                            return (
                                <div className={"my-4"}>
                                    <div className={"flex flex-wrap wrap "}>
                                        <div className={"md:w-2/5 text-wrap text-left "}>
                                            <h3 className={"text-xl text-emerald-500"}>
                                                <div>from {DateTime.fromISO(flight.departure_date).toFormat("ccc'.' dd/LL/yy 'at' HH:mm")}</div>
                                                <div>to {DateTime.fromISO(flight.return_date).toFormat("ccc'.' dd/LL/yy 'at' HH:mm")}</div>
                                            </h3>
                                            <div className={"flex align-left"}>
                                                {flight.airlines.map((airline: any) => {
                                                return (
                                                    <img
                                                        src={"https://images.kiwi.com/airlines/64x64/" + airline + ".png"}
                                                        className={"inline-block mr-2"} key={airline + flight.departure_date + destinationItem.cityTo} style={{width: '32px'}}/>
                                                )
                                            })}
                                                {flight.flyFromDep} - {flight.flyToDep} | {flight.flyFromRet} - {flight.flyToRet}
                                            </div>

                                        </div>
                                        <div className={"w-full md:w-1/5 text-wrap text-center m-auto"}>
                                            <h3 className={"text-2xl text-emerald-500"}>{flight.price} €</h3>
                                        </div>
                                        <div className={"w-full md:w-1/5 text-wrap text-center m-auto"}>
                                            <button
                                                className={"bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded transition"}
                                                onClick={() => {
                                                    window.open(flight.link, "_blank")
                                                }}>
                                                Get this deal !
                                            </button>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            )
                        })}
                    </div>
                )}


            </div>
        </div>
    )
}