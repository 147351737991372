import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import HomePage from "./components/HomePage";
import AddPage from "./components/AddPage";
import NotFoundPage from "./components/NotFoundPage";
import TermsAndConditions from "./components/TermsAndConditions";
import axios from "axios";
import {getAPIBaseUrl} from "./environment";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/addPage/:addToken",
        element: <AddPage/>

    },
    {
        path: "/terms_and_conditions",
        element: <TermsAndConditions/>

    }
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
axios.defaults.baseURL = getAPIBaseUrl();
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
        {/*<App />*/}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
